<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">เหตุผลการประเมิน</span>
      </template>

      <setting-category></setting-category>
    </b-tab>
    <!--/ general tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import SettingCategory from "./SettingCategory.vue";
import { ref } from "@vue/composition-api";
import { decodeToken } from "@/@core/utils/decodeJwt";
import { companyService } from "@/services/company";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BTabs,
    BTab,
    SettingCategory,
  },
  setup() {},
};
</script>
