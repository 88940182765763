<template>
  <b-card :title="$t('evaluations.reason')">
    <app-collapse accordion>
      <template v-for="(item, cateIndex) in categoryList">
        <app-collapse-item :title="item.category" :key="cateIndex">
          <b-row class="mb-2">
            <b-col lg="2" sm="4">
              <span class="mr-2">{{ $t("category.name") }} </span>
            </b-col>
            <b-col lg="4" sm="6">
              <b-form-input v-model="item.category" />
            </b-col>
            <b-col lg="3" sm="6">
              <b-form-checkbox
                v-model="item.requireDocument"
                switch
                style="margin-top: 7px"
              >
                ต้องอัพเอกสาร
              </b-form-checkbox>
            </b-col>
            <b-col lg="3" sm="6">
              <b-button
                v-show="cateIndex !== 0"
                type="submit"
                variant="danger"
                @click="removeCategory(cateIndex)"
                class="ml-1"
              >
                {{ $t("category.remove_sub_category") }}
              </b-button>
            </b-col>
          </b-row>
          <table class="options table table-bordered table-striped">
            <thead>
              <tr>
                <th>{{ $t("no") }}</th>
                <th>{{ $t("category.sub_category") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody id="option-0-select-values" class="sortable">
              <tr
                class="option-row"
                v-for="(subCate, index) in item.subCategory"
                :key="index"
              >
                <td class="text-center">
                  <span>{{ cateIndex + 1 }}.{{ index + 1 }}</span>
                </td>

                <td class="text-center">
                  <b-form-input v-model="subCate.name" />
                </td>
                <td class="text-center">
                  <feather-icon
                    @click="removeSubcategory(cateIndex, index)"
                    v-show="index !== 0"
                    icon="Trash2Icon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <b-button
            type="submit"
            variant="primary"
            class="mt-2"
            @click="newSubCategory(cateIndex)"
          >
            {{ $t("category.add_sub_category") }}
          </b-button>
        </app-collapse-item>
      </template>
    </app-collapse>

    <hr />

    <b-button type="submit" variant="primary" @click="manageAllReason">
      {{ $t("save") }}
    </b-button>
    <b-button type="submit" variant="success" @click="newCategory" class="ml-1">
      {{ $t("category.add") }}
    </b-button>
  </b-card>
</template>
<script>
import {
  BCard,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { onMounted, ref } from "@vue/composition-api";
import { reasonEvaluation } from "@/services/reason_evaluation";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
  },
  setup() {
    const toast = useToast();

    let categoryList = ref([
      {
        category: "New Category",
        subCategory: [{ name: "" }],
        requiredDocument: false,
      },
    ]);

    onMounted(() => {
      getAllReason();
    });

    const getAllReason = async () => {
      try {
        const result = await reasonEvaluation.getAllReason();

        if (result.status) {
          categoryList.value = result.data;
        }
      } catch (e) {
        console.log(e);
      }
    };

    const manageAllReason = async () => {
      try {
        const result = await reasonEvaluation.manageReason({
          evaluationList: categoryList.value,
        });

        if (result.status) {
          categoryList.value = result.data;
          toast({
            component: ToastificationContent,
            props: {
              title: "Manage reason success",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    const newSubCategory = (index) => {
      categoryList.value[index].subCategory.push({ category: "" });
    };

    const newCategory = () => {
      categoryList.value.push({
        category: `หมวดที่ ${categoryList.value.length + 1}`,
        subCategory: [{ name: "" }],
        requiredDocument: false,
      });
    };

    const removeSubcategory = (cateIndex, index) => {
      categoryList.value[cateIndex].subCategory.splice(index, 1);
    };

    const removeCategory = (index) => {
      categoryList.value.splice(index, 1);
    };

    return {
      categoryList,
      newCategory,
      newSubCategory,
      removeCategory,
      removeSubcategory,
      manageAllReason,
    };
  },
};
</script>
<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}
.options thead th:first-child {
  width: 40px;
}
.table > thead > tr > th {
  font-weight: 600;
  border: none;
  border-bottom: 1px solid #e9e9e9;
  font-size: 12px;
}
.table-bordered,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
}
.options thead th:last-child,
.options thead th:first-child {
  width: 60px;
}
.options thead th:first-child {
  width: 40px;
}
.show-image {
  height: 85px;
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  cursor: pointer;
}
</style>
