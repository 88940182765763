import axiosIns from "@/libs/axios";

export const reasonEvaluation = {
  manageReason: async (data) => {
    try {
      const response = await axiosIns.post(
        `/backoffice/reason-evaluation/`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getAllReason: async () => {
    try {
      const response = await axiosIns.get(`/backoffice/reason-evaluation/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
